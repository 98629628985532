(function() {
  var renderNumber = function (n) {
    var i = 0;
    while (n >= 1000) {
        n = Math.floor(n / 100) / 10
        i++;
    }
    m = ['', 'k', 'M', 'G', 'T'];
    return n + m[i];
  };

  var leftpad = function (str, len, ch) {
    str = String(str);
    var i = -1;
    if (!ch && ch !== 0) {
      ch = ' ';
    }
    len = len - str.length;
    while (++i < len) {
      str = ch + str;
    }
    return str;
  };

  var bDate = function (date) {
    const options = {
      timeZone: 'UTC',
      year: 'numeric', month: 'long', day: 'numeric'
    }
    return date.toLocaleDateString('en-US', options)
  };

  var MONTHS = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
  ];

  var MONTHS_SHORT = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec'
  ];

  var renderDate = function (dt, isShort) {
    var y = dt.getUTCFullYear();
    var m = dt.getUTCMonth();
    var d = dt.getUTCDate();
    if (isShort) {
      return MONTHS_SHORT[m] + ' ' + d + ', ' + y;
    }
    return MONTHS[m] + ' ' + d + ', ' + y;
  }

  var renderCurrency = function (n, ch) {
    ch = ch || ' ';
    var s = '';
    while (n >= 1000) {
      var a = n % 1000;
      s = ch + leftpad(a, 3, '0') + s;
      n = Math.floor(n / 1000);
    }
    return (n + s).trim();
  };

  var getStatsURL = function (spid) {
    return '/api/v3/providers/' + spid + '/plan.json';
  };
  var getReportsURL = function (spid) {
    return '/api/v3/providers/' + spid + '/report.json';
  };

   // * TODO @iv
   // * - rewrite requests to servers
   // * - rewrite names
   // * - refactoring
   // *
   // * TODO @vk
   // * - new API

  var getDataFromServer = function (url, element, fn, options) {
    fetch(url, {
      credentials: 'include'
      })
      .then(res => res.json())
      .then(res => {
        if (res.code) {
          element.parentNode.replaceChild(fn(Object.assign({}, res, options)), element);
        }
      }).catch(console.error);
  };

  var getProviderPlan = function (spid, element, fn, options) {
    var pull_url = getStatsURL(spid);
    getDataFromServer(pull_url, element, fn, options);
  };

  var getProviderReport = function (spid, element, fn, options) {
    var pull_url = getReportsURL(spid);
    getDataFromServer(pull_url, element, fn, options);
  };

  var isPluginPresent = () => {
    setTimeout(function () {
      if(WWPass.pluginPresent()) {
        var hardwarePassKeySet = document.querySelectorAll('.displayNone');
      if (hardwarePassKeySet.length) {
          [].forEach.call(hardwarePassKeySet, function (it) {
            it.classList.remove('displayNone');
          });
        }
      }
    }, 100);
  }

  var isMobileDevice = function() {
    return navigator.userAgent.match(/iPhone|iPod|iPad|Android/i);
  };

  window.Util = {
    renderCurrency: renderCurrency,
    renderNumber: renderNumber,
    renderDate: renderDate,
    getStatsURL: getStatsURL,
    getProviderPlan: getProviderPlan,
    getProviderReport: getProviderReport,
    isPluginPresent: isPluginPresent,
    isMobileDevice: isMobileDevice
  }
})();
